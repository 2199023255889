/* eslint-disable mobx/missing-observer */
import { Paper, Typography } from '@mui/material';
import React from 'react';
import { brandColors } from 'shared/theme/brandColors';
import { formatCurrency } from 'utilities/currencyUtils';

interface T4BlueHeaderProps {
	bigNumber?: number | null;
	currency: string;
	subheadlines?: (string | JSX.Element | null)[];
}

const T4BlueHeader: React.FC<T4BlueHeaderProps> = ({
	bigNumber,
	currency,
	subheadlines,
}) => {
	// Filter out null values and elements that should not be rendered
	const filteredSubheadlines = subheadlines?.filter((item) => {
		if (item === null) return false;
		return true;
	});
	
	return (
		<Paper
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				backgroundColor: brandColors.denim[500],
				color: 'white',
				p: 2,
			}}
			elevation={0}
		>
			{bigNumber != null && (
				<Typography variant="h2" color="inherit">
					{`${formatCurrency(bigNumber, {
						currency: currency,
					})} ${currency.toUpperCase()}`}
				</Typography>
			)}
			{subheadlines && <PipeDelimitedContent items={filteredSubheadlines} />}
		</Paper>
	);
};

export default T4BlueHeader;

interface PipeDelimitedContentProps {
	items?: (string | JSX.Element | null)[];
}

const PipeDelimitedContent: React.FC<PipeDelimitedContentProps> = ({
	items,
}) => {
	return (
		<Typography variant="body1" color="inherit">
			{items?.map((item, index) => (
				<React.Fragment key={index}>
					{item}
					{index < items.length - 1 && ' | '}
				</React.Fragment>
			))}
		</Typography>
	);
};
