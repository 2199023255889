import {
	bofaConnectionSettingsFieldDefinitions,
	bofaConnectionSettingsForm,
} from './bofa/contants';
import { BofaConnectionSettingsValidator } from './bofa/validators';
import {
	chasConnectionSettingsFieldDefinitions,
	chasConnectionSettingsForm,
} from './chas/constants';
import { ChasConnectionSettingsValidator } from './chas/validators';
import {
	wfbiConnectionSettingsFieldDefinitions,
	wfbiConnectionSettingsForm,
} from './wfbi/constants';
import { WfbiConnectionSettingsValidator } from './wfbi/validators';

export const getConnectionSettingsDefaultForm = (
	bankCode: string | null | undefined,
) => {
	switch (bankCode?.toLowerCase()) {
		case 'bofa':
			return bofaConnectionSettingsForm;
		case 'chas':
			return chasConnectionSettingsForm;
		case 'wfbi':
			return wfbiConnectionSettingsForm;
		default:
			return null;
	}
};

export const getConnectionSettingsFieldDefinitions = (
	bankCode: string | null | undefined,
) => {
	switch (bankCode?.toLowerCase()) {
		case 'bofa':
			return bofaConnectionSettingsFieldDefinitions;
		case 'chas':
			return chasConnectionSettingsFieldDefinitions;
		case 'wfbi':
			return wfbiConnectionSettingsFieldDefinitions;
		default:
			return [];
	}
};

export const getConnectionSettingsValidator = (
	bankCode: string | null | undefined,
) => {
	switch (bankCode?.toLowerCase()) {
		case 'bofa':
			return BofaConnectionSettingsValidator;
		case 'chas':
			return ChasConnectionSettingsValidator;
		case 'wfbi':
			return WfbiConnectionSettingsValidator;
		default:
			return null;
	}
};
