/* eslint-disable mobx/missing-observer */
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { FC, useState } from 'react';
import { paths } from 'shared/constants/paths';
import { TabulatedRow } from './TransactionDrawer/TabulatedRow';
import { ReconciliationSummary } from 'modules/clients/customer-api/src/api/cash4';
import { formatCurrency } from 'utilities/currencyUtils';
import {
	convertToPercentage,
	normalizeReconciliationStatus,
} from 'features/cash4/reconciliations/_hooks/useReconciliationCalculations';

export type ReconciliationSummaryViewProps = {
	summary: ReconciliationSummary;
	currencyCode: string;
};

export const ReconciliationSummaryView: FC<ReconciliationSummaryViewProps> = ({
	summary,
	currencyCode,
}) => {
	const reconciledWith = `${
		summary.projectedCount + summary.reportedCount
	} transactions`;

	return (
		<Box
			sx={{
				border: '1px solid black',
				p: 2,
				mt: 2,
				borderRadius: '5px',
			}}
		>
			<ReconciliationSummaryHeader summary={summary} />
			<TabulatedRow
				label="Variance"
				value={convertToPercentage(summary.variancePercentage)}
			/>
			<TabulatedRow
				label="Reconciled Amount"
				value={
					formatCurrency(summary.reconciledAmount, {
						currency: currencyCode,
					}) ?? new Intl.NumberFormat().format(summary.reconciledAmount)
				}
			/>
			<TabulatedRow
				label="Unreconciled Amount"
				value={
					formatCurrency(summary.unreconciledAmount, {
						currency: currencyCode,
					}) ?? new Intl.NumberFormat().format(summary.unreconciledAmount)
				}
			/>
			<TabulatedRow label="Reconciled With" value={reconciledWith} />
		</Box>
	);
};

export default ReconciliationSummaryView;

interface ReconciliationSummaryHeaderProps {
	summary: ReconciliationSummary;
}

const ReconciliationSummaryHeader: React.FC<
	ReconciliationSummaryHeaderProps
> = ({ summary }) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleViewClick = () => {
		handleClose();
		window.open(
			`${paths.cash4.reconciliations.href}/${summary.reconciliationRecordId}`,
			'_blank',
		);
	};

	const MenuItems = [
		<MenuItem onClick={handleViewClick} key={1}>
			<span>View</span>&nbsp;&nbsp;
			<OpenInNewIcon fontSize="small" />
		</MenuItem>,
	];

	const reconciliationStatus = `${normalizeReconciliationStatus(
		summary.reconciliationStatus,
	)}`;

	return (
		<>
			<Stack direction="row" spacing={1} alignItems="center">
				<Typography sx={{ fontWeight: '600' }}>Reconciliation</Typography>
				<Typography
					sx={{
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						...(reconciliationStatus && {
							color: 'secondary.main',
						}),
						fontWeight: '600',
					}}
				>
					{reconciliationStatus}
				</Typography>
				<Box flexGrow={1}></Box>
				<IconButton onClick={handleOpen} data-testid={'menu'}>
					<MoreVertIcon />
				</IconButton>
				<Menu
					id="reconciliation-summary-menu"
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
				>
					{MenuItems}
				</Menu>
			</Stack>
			<Divider sx={{ mt: 1, mb: 1 }} />
		</>
	);
};
