import { ExpandMore } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { User } from 'modules/clients/apiGateway/payments4';
import {
	FileContent,
	Payment,
	PaymentApprovalLevelStatusTypes,
	PaymentDocument,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4DataResponse2 } from 'modules/clients/types';
import moment, { Moment } from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import ModalBase from 'shared/components/modalBase';
import { useClients } from 'shared/hooks/useClients';
import { dateTimeReadFormat, formatDate } from 'shared/utilities/dateUtilities';
import { getPaymentBankStatusText } from '../utilities';

type PaymentHistory = {
	type: 'status' | 'levelStatus' | 'bankStatus';
	date: Moment | null;
	status: string | null | undefined;
	user: User | null;
	reasons: string[] | null;
	level?: number;
	document?: PaymentDocument;
};

export const PaymentHistoryTab: FC<{
	tabIndex: number;
	payment: Payment | null | undefined;
}> = ({ tabIndex, payment }) => {
	const [isDetailOpen, setIsDetailOpen] = useState<{
		[index: number]: boolean;
	}>({});

	const history = useMemo(() => {
		if (payment === undefined || payment === null) return [];

		const statusHistoryArr: PaymentHistory[] = payment?.statusHistory
			.filter((history) => history.createdBy?.userId.toLowerCase() !== 'bank') // filter out bank set status history records
			.map((history) => ({
				type: 'status',
				date: moment(history.createdOn),
				status: history.paymentStatusType,
				user: history.createdBy,
				reasons: history.reason ? [history.reason] : null,
			}));

		const levelStateArr: PaymentHistory[] =
			payment?.approvalState?.paymentApprovalLevelStates
				.filter(
					(history) =>
						history.status !==
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Pending
						],
				)
				.map((history) => {
					const historyInfo =
						history.status ===
						PaymentApprovalLevelStatusTypes[
							PaymentApprovalLevelStatusTypes.Approved
						]
							? { date: history.approvedOn, user: history.approvedBy }
							: { date: history.rejectedOn, user: history.rejectedBy };

					return {
						type: 'levelStatus',
						date: moment(historyInfo.date),
						status: history.status,
						user: historyInfo.user,
						level: history.level,
						reasons: history.rejectedReason ? [history.rejectedReason] : null,
					};
				}) ?? [];

		const bankStatusArr: PaymentHistory[] =
			payment?.bankStatusHistory.map((history) => ({
				type: 'bankStatus',
				date: moment(history.updatedOn),
				status: getPaymentBankStatusText(history.status),
				user: history.createdBy,
				reasons: history.reasons.map((reason) => reason.description),
				document: history.originalResponseDocument,
			})) ?? [];

		return statusHistoryArr
			.concat(levelStateArr)
			.concat(bankStatusArr)
			.sort((a, b) => {
				if (a.date !== null && b.date !== null) return b.date.diff(a.date);
				if (a.date === null && b.date !== null) return -1;
				if (b.date === null && a.date !== null) return 1;
				return 0;
			});
	}, [payment]);

	return tabIndex === 2 ? (
		<div role="tabpanel">
			<Grid container direction="column" sx={{ gap: 2 }}>
				{history.map((value, index) => {
					return (
						<Grid
							key={index}
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
						>
							<Typography variant="body1">
								{formatDate(value.date, dateTimeReadFormat)?.toUpperCase()}
							</Typography>
							<Typography variant="body1" fontWeight={500}>
								{`${
									value.level !== undefined
										? 'Approval Level ' + value.level + ': '
										: ''
								}${value.status}`}
							</Typography>
							<Typography variant="body1">
								{value.user?.email ?? value.user?.userId}
							</Typography>

							{value.type === 'bankStatus' ? (
								<BankStatusDetails
									paymentId={payment?.id}
									isOpen={isDetailOpen[index]}
									open={() =>
										setIsDetailOpen((prev) => ({ ...prev, [index]: true }))
									}
									close={() =>
										setIsDetailOpen((prev) => ({ ...prev, [index]: false }))
									}
									bankStatus={value}
								/>
							) : !!value.reasons ? (
								<Grid container item xs={12}>
									<T4Alert
										severity={
											payment?.currentStatus ===
												PaymentStatusTypes[PaymentStatusTypes.Rejected] ||
											payment?.currentStatus ===
												PaymentStatusTypes[PaymentStatusTypes.Canceled]
												? 'error'
												: 'info'
										}
										sx={{
											'&.MuiPaper-root': {
												height: '100%',
												width: '100%!important',
											},
										}}
									>
										<Box
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: 1,
											}}
										>
											<Typography variant="body2" fontWeight={500}>
												{payment?.currentStatus ===
												PaymentStatusTypes[PaymentStatusTypes.Rejected]
													? 'Rejection Reason'
													: payment?.currentStatus ===
													  PaymentStatusTypes[PaymentStatusTypes.Canceled]
													? 'Cancellation Reason'
													: 'Reason(s)'}
											</Typography>
											{value.reasons.length === 1 ? (
												<Typography variant="caption">
													{value.reasons}
												</Typography>
											) : null}
										</Box>
									</T4Alert>
								</Grid>
							) : null}
							{index !== history.length - 1 && (
								<Divider sx={{ paddingTop: '0.5rem' }} />
							)}
						</Grid>
					);
				})}
			</Grid>
		</div>
	) : null;
};

const BankStatusDetails: FC<{
	paymentId: string | undefined;
	isOpen: boolean;
	open: () => void;
	close: () => void;
	bankStatus: PaymentHistory;
}> = ({ paymentId, isOpen, open, close, bankStatus }) => {
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isFileContentOpen, setIsFileContentOpen] = useState<boolean>(false);
	const [file, setFile] = useState<FileContent | null>(null);
	const fileContent = useMemo(() => {
		if (file) {
			const rawFileContents = atob(file.fileContents);
			if (file.contentType === 'application/json') {
				return JSON.stringify(JSON.parse(rawFileContents), null, 2);
			} else return rawFileContents;
		}
	}, [file]);

	const getFile = useCallback(async () => {
		if (!paymentId || !bankStatus.document) return;
		try {
			setIsLoading(true);
			const response =
				await applicationApiClient.payments4.payments.getPaymentDocument({
					id: paymentId,
					documentId: bankStatus.document.paymentDocumentId,
				});

			if (response.status === 200 && response.data) {
				setFile((response.data as T4DataResponse2<FileContent>).data);
				setIsFileContentOpen(true);
			} else throw new Error();
		} catch {
			enqueueSnackbar(
				'Unable to retrieve payment document at this time. Please try again later.',
				{ variant: 'error' },
			);
		} finally {
			setIsLoading(false);
		}
	}, [paymentId, bankStatus.document, applicationApiClient, enqueueSnackbar]);

	return (
		<Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Button
					onClick={() => {
						if (!isOpen) open();
						else close();
					}}
					endIcon={
						<ExpandMore
							sx={{
								transition: 'rotate .25s',
								rotate: isOpen ? '180deg' : '0deg',
							}}
						/>
					}
					variant="text"
					color="secondary"
					sx={{ display: 'flex', justifyContent: 'center' }}
				>
					{isOpen ? 'Hide Details' : 'View Details'}
				</Button>
			</Box>

			<Collapse in={isOpen}>
				<Grid container sx={{ gap: 1, paddingX: '1rem', paddingY: '0.5rem' }}>
					<Grid container item xs={12} sx={{ flexDirection: 'column' }}>
						<Grid item xs>
							<Typography variant="body2" fontWeight={500}>
								Reasons
							</Typography>
						</Grid>
						<Grid item xs>
							<ul style={{ margin: 0 }}>
								{bankStatus.reasons?.map((value, index) => (
									<li key={index}>
										<Typography variant="body2">{value}</Typography>
									</li>
								))}
							</ul>
						</Grid>
					</Grid>
					<Button
						variant="outlined"
						color="secondary"
						onClick={async () => {
							if (!!file) setIsFileContentOpen(true);
							else await getFile();
						}}
						startIcon={isLoading && <CircularProgress size={20} />}
					>
						View Bank Response
					</Button>
				</Grid>
			</Collapse>

			<ModalBase
				title="Bank Response"
				headerActions={<CopyToClipboardIconButton value={fileContent ?? ''} />}
				open={!!file && isFileContentOpen}
				onClose={() => setIsFileContentOpen(false)}
				sx={{
					'& .MuiPaper-root': {
						width: '50%',
						maxWidth: '50%',
					},
				}}
			>
				<Box
					sx={(theme) => ({
						padding: '1rem',
						overflowY: 'auto',
						overflowX: 'auto',
						backgroundColor: theme.palette.cornflower[50],
						borderRadius: '4px',
					})}
				>
					<pre
						style={{
							margin: '0',
							whiteSpace: 'pre-wrap',
							fontSize: '0.875rem',
						}}
					>
						{fileContent}
					</pre>
				</Box>
			</ModalBase>
		</Box>
	);
};
