import { DateRange } from '@mui/x-date-pickers-pro';
import { flow, makeAutoObservable } from 'mobx';
import moment, { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { useAccountGroups } from 'shared/providers/accountGroupsProvider';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import { formatReadDate } from 'shared/utilities/dateUtilities';
import { formatCurrency } from 'utilities/currencyUtils';
import { BalancesCardModel } from './balancesCardModel';
import { BalanceListItem, BalancesModel } from './balancesModel';

export type BalanceValue = {
	value: number | null;
	valueCurrency: string | null;
	reportingValue: number | null;
};

export type Balance = Pick<
	BalanceListItem,
	| 'statementDate'
	| 'balanceImportedDate'
	| 'lastUpdatedDate'
	| 'e4AccountName'
	| 'e4AccountId'
	| 'e4AccountNumber'
	| 'c4AccountNumber'
	| 'bankCode'
	| 'bankName'
	| 'transactionCount'
	| 'debitTransactionsCount'
	| 'creditTransactionsCount'
	| 'currency'
	| 'foreignExchangeRate'
	| 'foreignExchangeDate'
	| 'note'
    | 'e4AccountType'
> & {
	id: string;
	openingLedgerBalance: BalanceValue;
	openingAvailableBalance: BalanceValue;
	totalCreditTransactions: BalanceValue;
	totalDebitTransactions: BalanceValue;
	closingLedgerBalance: BalanceValue;
	closingAvailableBalance: BalanceValue;
	forwardAvailableBalance0Day: BalanceValue;
	forwardAvailableBalance1Day: BalanceValue;
	forwardAvailableBalance2Day: BalanceValue;
	forwardAvailableBalance3Day: BalanceValue;
};

export type DisplayCurrency = 'original' | 'reporting';
export type DisplayCurrencyOptions = {
	id: DisplayCurrency;
	value: string;
};

export class BalancesViewModel {
	private balancesCardModel: BalancesCardModel;
	private balancesModel: BalancesModel;
	private selectedDisplayCurrency: DisplayCurrency = 'original';

	constructor(startDate: Moment, endDate: Moment) {
		makeAutoObservable(this);

		this.balancesCardModel = new BalancesCardModel();
		this.balancesModel = new BalancesModel(startDate, endDate);
	}

	public getSelectedDisplayCurrency(): string {
		return this.selectedDisplayCurrency;
	}

	public onDisplayCurrencyChange(value: DisplayCurrency) {
		this.selectedDisplayCurrency = value;
	}

	public async deleteBalance(balanceId: string) {
		return await this.balancesModel.deleteBalance(balanceId);
	}

	// card view model functions
	public areCardsLoading() {
		return this.balancesCardModel.loading;
	}

	public getCardError() {
		return this.balancesCardModel.error;
	}

	public getOpeningBalances(currencyCode: string | undefined) {
		if (
			currencyCode === undefined ||
			currencyCode === null ||
			currencyCode === ''
		) {
			return '';
		}

		return formatCurrency(
			this.balancesCardModel.balanceCards?.dailyAccountOpeningBalances,
			{
				currency: currencyCode,
			},
		);
	}

	public getClosingBalances(currencyCode: string | undefined) {
		if (
			currencyCode === undefined ||
			currencyCode === null ||
			currencyCode === ''
		) {
			return '';
		}

		return formatCurrency(
			this.balancesCardModel.balanceCards?.dailyAccountClosingBalances,
			{
				currency: currencyCode,
			},
		);
	}

	public getDate(): string {
		return `(as of ${formatReadDate(this.balancesCardModel.date)})`;
	}

	// list view model functions
	public isListLoading() {
		return this.balancesModel.loading;
	}
	public getListError() {
		return this.balancesModel.error;
	}
	public getListErrors() {
		return Object.values(this.balancesModel.errors).flatMap((errors) => errors);
	}

	public isReportingCurrency() {
		return this.selectedDisplayCurrency === 'reporting';
	}

	public currencyValueGetter(balanceValue: BalanceValue) {
		if (this.isReportingCurrency()) {
			return balanceValue.reportingValue;
		} else {
			return balanceValue.value;
		}
	}

	public getRows(): Balance[] {
		return this.balancesModel.balances.map(({ balanceId, ...balance }) => ({
			id: balanceId,
			statementDate: balance.statementDate,
			balanceImportedDate: balance.balanceImportedDate,
			lastUpdatedDate: balance.lastUpdatedDate,
			e4AccountName: balance.e4AccountName,
			e4AccountId: balance.e4AccountId,
			e4AccountNumber: balance.e4AccountNumber,
            e4AccountType: balance.e4AccountType,
			c4AccountNumber: balance.c4AccountNumber,
			bankCode: balance.bankCode,
			bankName: balance.bankName,
			openingLedgerBalance: {
				value: balance.openingLedgerBalance,
				reportingValue: balance.openingLedgerBalance_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			openingAvailableBalance: {
				value: balance.openingAvailableBalance,
				reportingValue: balance.openingAvailableBalance_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			totalCreditTransactions: {
				value: balance.totalCreditTransactions,
				reportingValue: balance.totalCreditTransactions_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			totalDebitTransactions: {
				value: balance.totalDebitTransactions,
				reportingValue: balance.totalDebitTransactions_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			closingLedgerBalance: {
				value: balance.closingLedgerBalance,
				reportingValue: balance.closingLedgerBalance_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			closingAvailableBalance: {
				value: balance.closingAvailableBalance,
				reportingValue: balance.closingAvailableBalance_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			forwardAvailableBalance0Day: {
				value: balance.forwardAvailableBalance0Day,
				reportingValue: balance.forwardAvailableBalance0Day_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			forwardAvailableBalance1Day: {
				value: balance.forwardAvailableBalance1Day,
				reportingValue: balance.forwardAvailableBalance1Day_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			forwardAvailableBalance2Day: {
				value: balance.forwardAvailableBalance2Day,
				reportingValue: balance.forwardAvailableBalance2Day_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			forwardAvailableBalance3Day: {
				value: balance.forwardAvailableBalance3Day,
				reportingValue: balance.forwardAvailableBalance3Day_ReportingCurrency,
				valueCurrency: balance.currency,
			},
			transactionCount: balance.transactionCount,
			debitTransactionsCount: balance.debitTransactionsCount,
			creditTransactionsCount: balance.creditTransactionsCount,
			currency: balance.currency,
			foreignExchangeRate: balance.foreignExchangeRate,
			foreignExchangeDate: balance.foreignExchangeDate,
			note: balance.note,
		}));
	}

	public onDateRangeChange(dateRange: DateRange<moment.Moment | null>) {
		this.balancesModel.setDateRange(dateRange);
	}
	public getStartDate() {
		return this.balancesModel.startDate;
	}
	public getEndDate() {
		return this.balancesModel.endDate;
	}

	public getDefaultDateRange = () => {
		return this.balancesModel.dateDefaults;
	};

	public getMaxDate() {
		return this.balancesModel.maxDate;
	}

	public refetch(legalEntityGroupIds: string[] = []) {
		this.load(legalEntityGroupIds);
	}

	public load = flow(function* (
		this: BalancesViewModel,
		legalEntityGroupIds: string[] = [],
		accountGroupIds: string[] = [],
	) {
		yield this.balancesModel.load(legalEntityGroupIds, accountGroupIds);
		yield this.balancesCardModel.load();
	});
}

export function useBalancesViewModel(
	startDate: Moment,
	endDate: Moment,
): BalancesViewModel {
	const [viewModel] = useState<BalancesViewModel>(
		new BalancesViewModel(startDate, endDate),
	);
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const { accountGroupIds } = useAccountGroups();

	useEffect(() => {
		viewModel.load(legalEntityGroupIds, accountGroupIds);
		// don't add account group ids to the dependency array
	}, [viewModel, legalEntityGroupIds]);

	return viewModel;
}
