import { ChevronLeft, ChevronRight, Share } from '@mui/icons-material';
import { DateRange, TabContext, TabPanel } from '@mui/lab';
import {
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Tooltip,
	Typography,
} from '@mui/material';
import deepEqual from 'deep-eql';
import { T4CopyButtonWrapper } from 'features/cash4/shared/components/T4SideDrawer/T4CopyButtonWrapper';
import { ProjectedTransactionDrawer } from 'features/cash4/transactions/components/ProjectedTransactions/ProjectedTransactionDrawer';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { paths } from 'shared/constants/paths';
import { useReconciliationsContext } from '../_providers/reconciliationsProvider';
import { ModifyReconciliationView } from '../_views/modifyReconciliationView';
import { ReconciliationView } from '../_views/reconciliationView';
import { useCreateReconciliation } from '../services';
import { C4CancelDialog } from './c4CancelDialog';
import { C4Drawer, C4DrawerProps } from './c4Drawer';
import { C4TransactionsDrawer } from './c4TransactionsDrawer';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useUser } from 'shared/hooks/useUser';
import { DeleteMenuItem } from 'shared/components/deleteMenuItem';
import { Reconciliation } from 'modules/clients/customer-api/src/api/cash4';
import {
	ConfirmationDialog,
	IConfirmationDialogProps,
} from 'shared/components/confirmationDialog';
import { Moment } from 'moment';

export enum ReconciliationMode {
	Create,
	View,
	Edit,
}

export enum ReconciliationTab {
	Selection,
	Detail,
}

export type ReportedTransactionInputProps = {
	reportedTransactionIds: string[];
	dateRange: DateRange<Moment>;
};

export type ReconciliationDrawerProps = {
	onClose?: () => void;
	onSuccess?: () => void;
	projectedTransactionIds?: string[];
	reportedTransactionProps?: ReportedTransactionInputProps;
};

export const ReconciliationDrawer: FC<ReconciliationDrawerProps> = observer(
	({
		onClose,
		onSuccess,
		projectedTransactionIds,
		reportedTransactionProps,
	}) => {
		const {
			open,
			mode,
			tab,
			reconciliation,
			selectedProjectedTransaction,
			projectedTransactionDrawerOpen,
			selectedTransaction,
			projectedTransactionsQueryContext: {
				refetch: refetchProjectedTransactions,
				loading: projectedTransactionsLoading,
			},
			getSingleReconciliationQuery: {
				refetch: refetchReconciliation,
				isFetching: reconciliationIsFetching,
			},
			editReconciliation,
			setSelectedTransaction,
			setProjectedTransactionDrawerOpen,
			setSelectedProjectedTransaction,
			setTab,
			updateReconciliation,
			onDrawerClose,
			notes,
			setMode,
			deleteReconciliation,
		} = useReconciliationsContext();
		const { cash4 } = useUser();
		const [cancelOpen, setCancelOpen] = useState(false);
		const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
		const [selectedDialog, setSelectedDialog] =
			useState<
				Pick<
					IConfirmationDialogProps,
					| 'title'
					| 'text'
					| 'primaryButtonText'
					| 'secondaryButtonText'
					| 'onPrimaryButtonClick'
					| 'onSecondaryButtonClick'
				>
			>();

		const [cancelReturnToViewOpen, setCancelReturnToViewOpen] = useState(false);
		const [selectedProjectedIds, setSelectedProjectedIds] = useState<string[]>(
			[],
		);

		const [selectedReportedIds, setSelectedReportedIds] = useState<string[]>(
			[],
		);

		useEffect(() => {
			!!projectedTransactionIds
				? setSelectedProjectedIds(projectedTransactionIds!)
				: setSelectedProjectedIds([]);
		}, [projectedTransactionIds]);

		useEffect(() => {
			!!reportedTransactionProps?.reportedTransactionIds
				? setSelectedReportedIds(
						reportedTransactionProps?.reportedTransactionIds!,
				  )
				: setSelectedReportedIds([]);
		}, [reportedTransactionProps?.reportedTransactionIds]);

		const [openTransactionDrawer, setOpenTransactionDrawer] =
			useState<boolean>(false);

		useEffect(() => {
			if (selectedTransaction?.id) {
				setOpenTransactionDrawer(true);
			}
		}, [selectedTransaction]);

		const isSubmitDisabled = useMemo(
			() =>
				(selectedProjectedIds?.length ?? 0) <= 0 ||
				(selectedReportedIds?.length ?? 0) <= 0 ||
				projectedTransactionsLoading ||
				reconciliationIsFetching,
			[
				projectedTransactionsLoading,
				reconciliationIsFetching,
				selectedProjectedIds?.length,
				selectedReportedIds?.length,
			],
		);

		const onCancelHandler = useCallback(() => {
			setCancelOpen(true);
		}, []);

		const onCancelReturnToViewHandler = useCallback(() => {
			setCancelReturnToViewOpen(true);
		}, []);

		const handleDeleteReconciliation = useCallback(
			async (reconciliation: Reconciliation) => {
				try {
					if (reconciliation) {
						await deleteReconciliation(reconciliation);
						onSuccess?.();
					}
				} catch {
				} finally {
					setOpenConfirmationDialog(false);
				}
			},
			[deleteReconciliation, onSuccess],
		);

		const onCloseHandler = useCallback(
			(keepOpen: boolean = false) => {
				setCancelOpen(false);
				if (!keepOpen) {
					onClose?.();
					onDrawerClose();
				}
			},
			[onClose, onDrawerClose],
		);

		const onDeleteClickHandler = useCallback(
			(reconciliation: Reconciliation) => {
				setSelectedDialog({
					title: 'Delete reconciliation?',
					text: 'This reconciliation will be permanently deleted from the system. All associated transactions will become unreconciled. This action cannot be undone.',
					primaryButtonText: 'DELETE',
					secondaryButtonText: 'CANCEL',
					onPrimaryButtonClick: async () => {
						await handleDeleteReconciliation(reconciliation);
						onCloseHandler();
					},
					onSecondaryButtonClick: () => {
						setOpenConfirmationDialog(false);
					},
				});

				setOpenConfirmationDialog(true);
			},
			[handleDeleteReconciliation, onCloseHandler],
		);

		const onForceCloseHandler = useCallback(() => {
			onCloseHandler();
		}, [onCloseHandler]);

		const onReturnToViewHandler = useCallback(() => {
			setSelectedProjectedIds(
				reconciliation?.projectedTransactions.map((item) => item.id) ?? [],
			);
			setSelectedReportedIds(
				reconciliation?.reportedTransactions.map((item) => item.id) ?? [],
			);
			onCloseHandler(true);
			setCancelReturnToViewOpen(false);
			setMode(ReconciliationMode.View);
		}, [
			onCloseHandler,
			reconciliation?.projectedTransactions,
			reconciliation?.reportedTransactions,
			setMode,
		]);

		const isCreateDirty = useMemo(
			() =>
				mode === ReconciliationMode.Create &&
				(selectedProjectedIds.length > 0 ||
					selectedReportedIds.length > 0 ||
					(notes?.length ?? 0) > 0),
			[
				mode,
				notes?.length,
				selectedProjectedIds.length,
				selectedReportedIds.length,
			],
		);

		const isEditDirty = useMemo(
			() =>
				mode === ReconciliationMode.Edit &&
				reconciliation &&
				(!deepEqual(
					selectedProjectedIds,
					reconciliation.projectedTransactions.map((item) => item.id),
				) ||
					!deepEqual(
						selectedReportedIds,
						reconciliation.reportedTransactions.map((item) => item.id),
					) 
                    ||
					!deepEqual(notes ?? '', reconciliation.notes ?? '')
                ),
			[mode, notes, reconciliation, selectedProjectedIds, selectedReportedIds],
		);

		const onSwitchToViewHandler = useCallback(() => {
			if (isEditDirty) {
				onCancelReturnToViewHandler();
			} else {
				onCloseHandler(true);
				setMode(ReconciliationMode.View);
			}
		}, [isEditDirty, onCancelReturnToViewHandler, onCloseHandler, setMode]);

		const onClickAwayHandler = useCallback(() => {
			if (mode === ReconciliationMode.View) {
				onCloseHandler();
			} else {
				if (isCreateDirty || isEditDirty) {
					onCancelHandler();
				} else {
					onCloseHandler();
				}
			}
		}, [isCreateDirty, isEditDirty, mode, onCancelHandler, onCloseHandler]);

		const onEditClickHandler = useCallback(
			(reconciliation: Reconciliation | undefined) => {
				if (reconciliation) {
					editReconciliation(reconciliation);
				}
			},
			[editReconciliation],
		);

		const createReconMutation = useCreateReconciliation(onDrawerClose);

		const title = useMemo(() => {
			let text = 'Transaction Reconciliation';
			switch (mode) {
				case ReconciliationMode.Create:
					text = 'Create Transaction Reconciliation';
					break;

				case ReconciliationMode.Edit:
					text = 'Edit Transaction Reconciliation';
					break;
			}

			return (
				<Grid
					container
					sx={{ alignItems: 'center', justifyContent: 'space-between' }}
				>
					<Grid item xs="auto">
						<Typography variant="h3">{text}</Typography>
					</Grid>

					<Grid
						item
						xs="auto"
						sx={{
							alignItems: 'center',
							justifyContent: 'space-between',
							pr: 1,
						}}
					>
						{mode === ReconciliationMode.View && (
							<T4CopyButtonWrapper
								copyText={`${window.location.origin}${paths.cash4.reconciliations.href}/${reconciliation?.id}`}
								initialTooltipText="Copy link."
							>
								<IconButton>
									<Share />
								</IconButton>
							</T4CopyButtonWrapper>
						)}
						{mode === ReconciliationMode.View && cash4.isAuthor && (
							<OverflowMenu id={`reconciliation-more-menu`}>
								{mode !== ReconciliationMode.View && (
									<MenuItem onClick={onSwitchToViewHandler}>
										View Details
									</MenuItem>
								)}
								<MenuItem
									onClick={() => {
										onEditClickHandler(reconciliation);
									}}
								>
									Edit Reconciliation
								</MenuItem>

								<Divider />

								<DeleteMenuItem
									objecttype="Reconciliation"
									onClick={() => {
										onDeleteClickHandler(reconciliation!);
									}}
								/>
							</OverflowMenu>
						)}
					</Grid>
				</Grid>
			);
		}, [
			cash4.isAuthor,
			mode,
			onDeleteClickHandler,
			onEditClickHandler,
			onSwitchToViewHandler,
			reconciliation,
		]);

		const actions = useMemo<C4DrawerProps['actions']>(() => {
			let actions: C4DrawerProps['actions'] = {};

			const closeButton = (
				<T4Button color="secondary" onClick={onClickAwayHandler}>
					Close
				</T4Button>
			);

			const cancelButton = (
				<T4Button color="secondary" onClick={onClickAwayHandler}>
					Cancel
				</T4Button>
			);

			const addDetailHoverText = () => {
				let text = '';
				if (selectedReportedIds.length === 0) {
					text += 'reported transaction';
				}
				if (selectedProjectedIds.length === 0) {
					text += selectedReportedIds.length === 0 ? ' and one ' : '';
					text += 'projected transaction';
				}

				return text;
			};

			switch (mode) {
				case ReconciliationMode.View:
					actions = {
						end: [closeButton],
					};
					break;

				default:
					actions = {
						start:
							tab === ReconciliationTab.Detail
								? [
										<T4Button
											color="secondary"
											variant="outlined"
											startIcon={<ChevronLeft />}
											onClick={() => setTab(ReconciliationTab.Selection)}
										>
											Select Transactions
										</T4Button>,
								  ]
								: undefined,
						end:
							tab === ReconciliationTab.Selection
								? [
										cancelButton,
										<Tooltip
											title={
												selectedProjectedIds.length === 0 ||
												selectedReportedIds.length === 0
													? `At least one ${addDetailHoverText()} must be selected in order to reconcile.`
													: ''
											}
										>
											<span>
												<T4Button
													variant="contained"
													endIcon={<ChevronRight />}
													onClick={() => setTab(ReconciliationTab.Detail)}
													disabled={isSubmitDisabled}
												>
													{`${
														mode === ReconciliationMode.Create ? 'Add' : 'Edit'
													} Details`}
												</T4Button>
											</span>
										</Tooltip>,
								  ]
								: [
										cancelButton,
										<T4Button
											variant="contained"
											disabled={isSubmitDisabled}
											onClick={async () => {
												if (mode === ReconciliationMode.Create) {
													await createReconMutation.mutateAsync({
														projectedTransactions: selectedProjectedIds,
														reportedTransactions: selectedReportedIds,
														note: notes,
													});
												} else {
													await updateReconciliation(
														selectedProjectedIds,
														selectedReportedIds,
													);
												}
												onSuccess?.();
											}}
										>
											{mode === ReconciliationMode.Create ? 'Create' : 'Save'}
										</T4Button>,
								  ],
					};
					break;
			}

			return actions;
		}, [
			createReconMutation,
			isSubmitDisabled,
			mode,
			notes,
			onClickAwayHandler,
			onSuccess,
			selectedProjectedIds,
			selectedReportedIds,
			setTab,
			tab,
			updateReconciliation,
		]);

		const cancellationModalDetails = useMemo(() => {
			let details = {
				title: 'Cancel reconciliation creation?',
				description:
					'Canceling reconciliation creation will discard any data you have entered, and a reconciliation will not be created.',
			};

			switch (mode) {
				case ReconciliationMode.Edit:
					details.title = 'Discard edits?';
					details.description =
						'Discarding will remove all changes made to the reconciliation.';
					break;
			}

			return details;
		}, [mode]);

		const createEditReconciliation = useMemo(() => {
			return (
				<ModifyReconciliationView
					selectedProjectedIds={selectedProjectedIds}
					setSelectedProjectedIds={setSelectedProjectedIds}
					selectedReportedIds={selectedReportedIds}
					setSelectedReportedIds={setSelectedReportedIds}
				/>
			);
		}, [selectedProjectedIds, selectedReportedIds]);

		useEffect(() => {
			if (reconciliation) {
				setSelectedProjectedIds(
					reconciliation.projectedTransactions.map((item) => item.id),
				);
				setSelectedReportedIds(
					reconciliation.reportedTransactions.map((item) => item.id),
				);
			} else if (!open && reconciliation === undefined) {
				setSelectedProjectedIds([]);
				setSelectedReportedIds([]);
			}
		}, [open, reconciliation]);

		useEffect(() => {
			if (!open) {
				setSelectedProjectedIds([]);
				setSelectedReportedIds([]);
			}
		}, [open]);

		return (
			<C4Drawer
				open={open}
				title={title}
				actions={actions}
				onClickAway={onClickAwayHandler}
				sx={{
					'& .MuiDrawer-paper': {
						width: '80%',
					},
				}}
			>
				<TabContext value={mode.toString()}>
					<TabPanel
						value={ReconciliationMode.View.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						<ReconciliationView reconciliation={reconciliation} />
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Create.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						{createEditReconciliation}
					</TabPanel>
					<TabPanel
						value={ReconciliationMode.Edit.toString()}
						sx={{ padding: 0, width: '100%' }}
					>
						{createEditReconciliation}
					</TabPanel>
				</TabContext>
				<C4CancelDialog
					open={cancelOpen}
					title={cancellationModalDetails.title}
					description={cancellationModalDetails.description}
					onCancel={() => setCancelOpen(false)}
					onConfirm={onForceCloseHandler}
					color={mode === ReconciliationMode.Create ? 'error' : 'warning'}
				/>
				{/* This second C4CancelDialog is to handle changing between edit and view */}
				<C4CancelDialog
					open={cancelReturnToViewOpen}
					title={cancellationModalDetails.title}
					description={cancellationModalDetails.description}
					onCancel={() => setCancelReturnToViewOpen(false)}
					onConfirm={onReturnToViewHandler}
					color={mode === ReconciliationMode.Create ? 'error' : 'warning'}
				/>
				<ProjectedTransactionDrawer
					isOpen={projectedTransactionDrawerOpen}
					projectedTransaction={selectedProjectedTransaction}
					onClose={() => {
						setProjectedTransactionDrawerOpen(false);
						setSelectedProjectedTransaction(undefined);
					}}
					onSubmit={(id) => {
						if (id) {
							setSelectedProjectedIds((prev) => [...prev, id]);
						}
						if (reconciliation) {
							refetchReconciliation();
						}
						refetchProjectedTransactions();
					}}
				/>
				{selectedTransaction && (
					<C4TransactionsDrawer
						isOpen={openTransactionDrawer}
						transactionId={selectedTransaction.id}
						onClose={() => {
							setSelectedTransaction(undefined);
							setOpenTransactionDrawer(false);
						}}
					/>
				)}
				<ConfirmationDialog
					open={openConfirmationDialog}
					onClose={() => setOpenConfirmationDialog(false)}
					title={selectedDialog?.title ?? ''}
					text={selectedDialog?.text ?? ''}
					primaryButtonText={selectedDialog?.primaryButtonText}
					secondaryButtonText={selectedDialog?.secondaryButtonText}
					onPrimaryButtonClick={selectedDialog?.onPrimaryButtonClick}
					onSecondaryButtonClick={selectedDialog?.onSecondaryButtonClick}
					stonlyIds={{}}
					loading={false}
				/>
			</C4Drawer>
		);
	},
);
