/* eslint-disable mobx/missing-observer */
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Divider, Grid, Tooltip } from '@mui/material';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	Account,
	BankConnection,
	CreateBankConnectionRequest,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';
import { T4DataResponse2, T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { T4MultiSelectAutocompleteVertical } from 'shared/components/autocompletes/t4MultiselectAutocompleteVertical';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { useClients } from 'shared/hooks/useClients';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { ConnectionSettingsForm } from './connectionSettingsForm';
import {
	BankConnectionForm,
	BankConnectionTypes,
	defaultBankConnectionForm,
} from './constants';
import { getConnectionSettingsDefaultForm } from './utilities';
import { BankConnectionFormValidator } from './validators';

export const CreateBankConnectionDrawer: FC<{
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	refetch: () => Promise<QueryObserverResult<BankConnection[], Error>>;
	getBankTabIndex: (bankCode: string) => number;
	setTabIndex: (value: number) => void;
}> = ({ isOpen, setIsOpen, refetch, getBankTabIndex, setTabIndex }) => {
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const formMethods = useForm<BankConnectionForm>({
		defaultValues: defaultBankConnectionForm,
		resolver: yupResolver(BankConnectionFormValidator),
	});
	const { control, watch, setValue, reset, handleSubmit, formState } =
		formMethods;

	const closeDrawer = useCallback(() => {
		setIsOpen(false);
		reset(defaultBankConnectionForm);
		setIsLoading(false);
		setErrors([]);
	}, [setIsOpen, reset]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useQuery<FinancialInstitution[], Error>(
		['financial-institutions'],
		async () => {
			const response =
				await applicationApiClient.financialInstitutions.getAll();
			if (response.status === 200 && response.data)
				return (
					response.data as T4DataResponse2<FinancialInstitution[]>
				).data.sort((a, b) =>
					(
						a.displayName ??
						a.bankCode ??
						a.financialInstitutionId
					).localeCompare(
						b.displayName ?? b.bankCode ?? b.financialInstitutionId,
					),
				);
			else throw new Error();
		},
		{
			enabled: isOpen,
			refetchOnWindowFocus: false,
			staleTime: 120000,
		},
	);
	const {
		isLoading: areCertificateNamesLoading,
		isFetching: areCertificateNamesFetching,
		data: certificateNames,
		error: loadingCertificateNamesError,
	} = useQuery<string[], Error>(
		['certificate-names'],
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllCertificateNames();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<string[]>).data.sort((a, b) =>
					a.localeCompare(b),
				);
			else throw new Error();
		},
		{
			enabled: isOpen,
			refetchOnWindowFocus: false,
		},
	);
	useEffect(() => {
		if (loadingFinancialInstitutionsError || loadingCertificateNamesError) {
			setIsOpen(false);
			reset(defaultBankConnectionForm);
			enqueueSnackbar(
				'Unable to load supporting data to enable bank connection creation. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [
		loadingFinancialInstitutionsError,
		loadingCertificateNamesError,
		setIsOpen,
		reset,
		enqueueSnackbar,
	]);

	const isInitializing = useMemo(
		() =>
			(areFinancialInstitutionsFetching && areFinancialInstitutionsLoading) ||
			(areCertificateNamesFetching && areCertificateNamesLoading),
		[
			areFinancialInstitutionsFetching,
			areFinancialInstitutionsLoading,
			areCertificateNamesFetching,
			areCertificateNamesLoading,
		],
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);
	const onCreate = useCallback(
		async (data: BankConnectionForm) => {
			try {
				setIsLoading(true);
				setErrors([]);
				const request = trimStringsInObject({
					name: data.name,
					bankCode: data.bank!.bankCode!,
					isGlobalConnection: data.connectionType === 'Global',
					connectionSettings: data.connectionSettings! as {
						[key: string]: string;
					},
					e4EntityIds: data.e4Entities.map((x) => x.id),
					e4AccountIds: data.e4Accounts.map((x) => x.id),
				}) as CreateBankConnectionRequest;

				const response =
					await applicationApiClient.payments4.bankConnections.create(request);
				if (response.status === 200 && response.data) {
					closeDrawer();
					await refetch();
					setTabIndex(getBankTabIndex(request.bankCode));
					enqueueSnackbar('Payment template created successfully.', {
						variant: 'success',
					});
				} else if (response.status === 400 && response.data) {
					setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
					errorsRef?.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			} catch (err) {
			} finally {
				setIsLoading(false);
			}
		},
		[
			applicationApiClient,
			closeDrawer,
			refetch,
			getBankTabIndex,
			setTabIndex,
			enqueueSnackbar,
		],
	);

	const selectedBank = watch('bank');

	// #region Related Data Loading + Input

	const selectedConnectionType = watch('connectionType');
	const {
		isLoading: areLegalEntitiesLoading,
		isFetching: areLegalEntitiesFetching,
		data: legalEntities,
		error: loadingLegalEntitiesError,
	} = useQuery<LegalEntity[], Error>(
		['bank-connection-legal-entities'],
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllLegalEntities();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<LegalEntity[]>).data.sort(
					(a, b) => a.displayName.localeCompare(b.displayName),
				);
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled: isOpen && selectedConnectionType === 'Entity',
		},
	);

	const {
		isLoading: areAccountsLoading,
		isFetching: areAccountsFetching,
		data: accounts,
		error: loadingAccountsError,
	} = useQuery<Account[], Error>(
		['bank-connection-accounts'],
		async () => {
			const response =
				await applicationApiClient.payments4.bankConnections.getAllAccounts();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<Account[]>).data.sort((a, b) =>
					a.displayName.localeCompare(b.displayName),
				);
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled: isOpen && selectedConnectionType === 'Account',
		},
	);

	const relatedObjectSelector = useMemo(() => {
		if (selectedConnectionType === 'Entity')
			return (
				<Grid item xs={12}>
					{loadingLegalEntitiesError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Legal Entities. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Entities'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<LegalEntity>
									id="e4-entities"
									value={value}
									options={legalEntities ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Entities',
										error: !!error,
										helperText: error && error.message,
										required: true,
									}}
									loading={areLegalEntitiesFetching && areLegalEntitiesLoading}
									readOnly={areLegalEntitiesFetching && areLegalEntitiesLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else if (selectedConnectionType === 'Account')
			return (
				<Grid item xs={12}>
					{loadingAccountsError ? (
						<Alert
							severity="warning"
							sx={{ '&.MuiPaper-root': { marginTop: 0, height: '100%' } }}
						>
							Unable to load Accounts. Please try again later.
						</Alert>
					) : (
						<Controller
							name={'e4Accounts'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4MultiSelectAutocompleteVertical<Account>
									id="e4-accounts"
									value={value}
									options={accounts ?? []}
									onChange={(_, value) => onChange(value)}
									getOptionKey={(option) => option.id}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									textFieldProps={{
										label: 'Accounts',
										error: !!error,
										helperText: error && error.message,
										required: true,
									}}
									loading={areAccountsFetching && areAccountsLoading}
									readOnly={areAccountsFetching && areAccountsLoading}
								/>
							)}
						/>
					)}
				</Grid>
			);
		else return null;
	}, [
		control,
		selectedConnectionType,
		legalEntities,
		accounts,
		areLegalEntitiesFetching,
		areLegalEntitiesLoading,
		loadingLegalEntitiesError,
		areAccountsFetching,
		areAccountsLoading,
		loadingAccountsError,
	]);

	// #endregion

	return (
		<T4DrawerBase
			open={isOpen}
			onClose={closeDrawer}
			initializing={isInitializing}
			loading={isLoading}
			actions={[
				<DrawerCancelButton onCancel={closeDrawer} />,
				<DrawerSubmitButton
					onSubmit={handleSubmit(onCreate)}
					label="Create"
					disabled={!formState.isDirty || isLoading || isInitializing}
				/>,
			]}
			disableNavigationCollapse
		>
			<FormProvider {...formMethods}>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						<Controller
							name={'bank'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4Autocomplete<FinancialInstitution>
										id="bank"
										label="Bank"
										value={value}
										options={financialInstitutions ?? []}
										onChange={(
											_: any,
											newValue: FinancialInstitution | null,
										) => {
											if (
												newValue?.financialInstitutionId !==
												value?.financialInstitutionId
											) {
												onChange(newValue);

												if (newValue === null)
													setValue('connectionSettings', null);
												else
													setValue(
														'connectionSettings',
														getConnectionSettingsDefaultForm(newValue.bankCode),
													);
											}
										}}
										isOptionEqualToValue={(option, value) =>
											option.bankCode === value.bankCode
										}
										getOptionLabel={(option) =>
											option.displayName ??
											option.bankCode ??
											option.financialInstitutionId
										}
										getOptionDisabled={(option) =>
											!option.isWirePaymentsEnabled
										}
										renderOption={(props, option, _, ownerState) => {
											const labelBox = (
												<Box
													component="li"
													{...props}
													key={props.id ?? option.financialInstitutionId}
												>
													{ownerState.getOptionLabel(option)}
												</Box>
											);
											if (!option.isWirePaymentsEnabled) {
												return (
													<Box key={option.financialInstitutionId}>
														<Tooltip title="This counterparty is not enabled for wire payments yet. Please contact your administrator to enable wire payments for this counterparty.">
															<span>{labelBox}</span>
														</Tooltip>
													</Box>
												);
											}

											return labelBox;
										}}
										error={!!error}
										helperText={error && error.message}
										required
									/>
								);
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name={'connectionType'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4Autocomplete<string, false, true>
										id="connectionType"
										label="Connection Type"
										value={value}
										options={BankConnectionTypes}
										onChange={(_: any, newValue: string) => {
											if (newValue !== value) {
												onChange(newValue);
												setValue('e4Entities', []);
												setValue('e4Accounts', []);
											}
										}}
										error={!!error}
										helperText={error && error.message}
										disableClearable
										required
									/>
								);
							}}
						/>
					</Grid>

					{relatedObjectSelector}

					<Grid item xs={12}>
						<Controller
							name={'name'}
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => {
								return (
									<T4TextFieldV2
										id="connection-name"
										label="Connection Name"
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error && error.message}
										required
									/>
								);
							}}
						/>
					</Grid>

					{!!selectedBank && (
						<Grid item xs={12}>
							<Divider />
						</Grid>
					)}

					<ConnectionSettingsForm certificateNames={certificateNames ?? []} />

					{Object.values(errors).length > 0 && (
						<Grid item xs={12} ref={errorsRef}>
							<T4AlertStack errors={errors} />
						</Grid>
					)}
				</Grid>
			</FormProvider>
		</T4DrawerBase>
	);
};
