import { AxiosInstance } from 'axios';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { Read, T4ApiResponse, Write } from 'modules/clients/types';
import { User } from '..';

export enum PaymentStatusTypes {
	Default = 0,
	Created = 1,
	Submitted = 2,
	Approved = 3,
	Initiated = 4,
	Transmitted = 5,
	Pending = 6,
	Rejected = 7,
	Failed = 8,
	Completed = 9,
	Canceled = 10,
}

export enum PaymentBankStatusTypes {
	Default = 0,
	Pending = 1,
	Accepted = 2,
	Processing = 3,
	Rejected = 4,
	Canceled = 5,
	Completed = 6,
	Failed = 7,
	Unrecognized = 8,
	NotFound = 9,
}

export enum PaymentPriorityTypes {
	Urgp = 1,
}

export enum PaymentTypes {
	Wire = 1,
	Ach = 2,
	BookTransfer = 3,
}

export type Payment = {
	id: string;
	paymentTemplateId: string | null;
	paymentTemplateName: string | null;
	currentStatus: string;
	currentBankStatus: string | null;
	paymentType: string;
	priorityType: string;
	endToEndId: string;
	bankTransactionId: string;
	initiator: PaymentParty;
	payee: PaymentParty;
	instructedAmount: PaymentAmount;
	referenceData: string | null;
	createdOn: string;
	valueDate: string;
	statusHistory: PaymentStatusHistory[];
	bankStatusHistory: PaymentBankStatus[];
	approvalState: PaymentApprovalState | null;
};

export type PaymentParty = {
	entityId: string;
	partyType: EntityTypeId;
	name: string;
	displayName: string;
	countryCode: string;
	accountId: string;
	accountNumber: string;
	accountName: string;
	accountDisplayName: string;
	bank: PaymentBank;
	needsProjectedTransaction: boolean;
	cashFlowClassId: string | null;
	cashFlowTypeId: string | null;
	cashFlowSubtypeId: string | null;
	glCodeId: string | null;
};

export type FreeFormPaymentParty = {
	name: string;
	countryCode: string;
	accountNumber: string;
	accountName: string;
	bank: FreeFormPaymentBank;
};

export type PaymentBank = {
	entityId: string;
	name: string;
	displayName: string;
	bankCode: string;
	countryCode: string;
	aba: string | null;
	bic: string | null;
};

export type FreeFormPaymentBank = {
	name: string;
	bankCode: string;
	countryCode: string;
	aba: string | null;
	bic: string | null;
};

export type PaymentAmount = {
	value: number | null;
	currencyCode: string | null;
};

export type PaymentStatusHistory = {
	paymentStatusType: string;
	reason: string | null;
	createdBy: User | null;
	createdOn: string;
};

export type PaymentBankStatus = {
	id: string;
	status: string;
	originalBankStatus: string;
	reasons: PaymentBankStatusReason[];
	createdOn: string;
	createdBy: User;
	updatedOn: string;
	originalResponseDocument: PaymentDocument;
};

export type PaymentBankStatusReason = {
	code: string;
	description: string;
};

export type PaymentDocument = {
	paymentDocumentId: string;
	name: string;
	originalName: string;
	contentType: string;
	description: string;
	createdBy: User;
	createdOn: string;
};

export type PaymentApprovalState = {
	id: string;
	paymentId: string;
	approvalRuleId: string;
	approvalRuleVersion: number;
	approvalRuleTierId: string;
	currentApprovalLevelId: string;
	isAutoApprove: boolean;
	canApproveOwnPayments: boolean;
	hasSequentialApprovers: boolean;
	requiresUniqueApprovers: boolean;
	currentStatus: string | null;
	isCompleted: boolean;
	paymentCreatedBy: User | null;
	paymentApprovalLevelStates: PaymentApprovalLevelState[];
};

export type PaymentApprovalLevelState = {
	id: string;
	paymentApprovalStateId: string;
	paymentApprovalLevelId: string;
	level: number;
	status: string | null;
	approvedBy: User | null;
	approvedOn: string | null;
	rejectedBy: User | null;
	rejectedOn: string | null;
	rejectedReason: string | null;
	approvers: User[] | null;
};

export enum PaymentApprovalLevelStatusTypes {
	Pending = 1,
	Approved = 2,
	Rejected = 3,
}

export type FileContent = {
	fileContents: string; // base64 encoded file contents
	contentType: string;
	fileDownloadName: string;
};

// #region Requests

export type SubmitFreeFormPaymentRequest = {
	paymentType: string;
	priorityType: string;
	initiator: FreeFormPaymentParty;
	payee: FreeFormPaymentParty;
	instructedAmount: PaymentAmount;
	referenceData: string | null;
	valueDate: string; // DateOnly
};

export type RejectPaymentRequest = {
	reason: string | null;
};

export type CancelPaymentRequest = {
	reason: string;
};

// #endregion

export type PaymentEndpoints = {
	get: Read<T4ApiResponse<Payment>, string>;
	getBankStatus: Read<T4ApiResponse<any>, string>; // type according to what is sent back
	getPaymentDocument: Read<
		T4ApiResponse<FileContent>,
		{ id: string; documentId: string }
	>;
	getAll: Read<
		T4ApiResponse<Payment[]>,
		{ startDate?: string; endDate?: string }
	>;
	submit: Write<T4ApiResponse<Payment>, SubmitFreeFormPaymentRequest>;
	resubmit: Write<T4ApiResponse<Payment>, string>;
	cancel: Write<
		T4ApiResponse<Payment>,
		{ id: string; data: CancelPaymentRequest }
	>;
	approve: Write<T4ApiResponse<Payment>, string>;
	reject: Write<
		T4ApiResponse<Payment>,
		{ id: string; data: RejectPaymentRequest }
	>;
};

export function payments(axiosInstance: AxiosInstance): PaymentEndpoints {
	return {
		get: async (id, config = {}) => {
			return await axiosInstance.get(`payments4/payments/${id}`, {
				...config,
			});
		},
		getBankStatus: async (id, config = {}) => {
			return await axiosInstance.get(
				`payments4/payments/${id}/bankstatusinquiry`,
				{
					...config,
				},
			);
		},
		getPaymentDocument: async (
			params: { id: string; documentId: string },
			config: any = {},
		) => {
			return await axiosInstance.get(
				`payments4/payments/${params.id}/documents/${params.documentId}`,
				{
					...config,
				},
			);
		},
		getAll: async (
			filters: { startDate?: string; endDate?: string },
			config: any = {},
		) => {
			return await axiosInstance.get('payments4/payments', {
				...config,
				params: {
					startDate: filters?.startDate,
					endDate: filters?.endDate,
				},
			});
		},
		submit: async (data, config = {}) => {
			return await axiosInstance.post('payments4/payments/submit', data, {
				...config,
			});
		},
		resubmit: async (id, config = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/submit`, {
				...config,
			});
		},
		cancel: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/cancel`, data, {
				...config,
			});
		},
		approve: async (id, config = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/approve`, {
				...config,
			});
		},
		reject: async ({ id, data }, config: any = {}) => {
			return await axiosInstance.put(`payments4/payments/${id}/reject`, data, {
				...config,
			});
		},
	};
}
