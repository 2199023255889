import * as yup from 'yup';
import {
	BankConnectionForm,
	BankConnectionSettingsType,
	BankConnectionType,
	BankConnectionTypes,
} from './constants';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import { getConnectionSettingsValidator } from './utilities';
import {
	Account,
	LegalEntity,
} from 'modules/clients/apiGateway/payments4/bankConnections';

export const BankConnectionFormValidator: yup.ObjectSchema<BankConnectionForm> =
	yup.object({
		name: yup.string().trim().label('Connection Name').required(),
		bank: yup.mixed<FinancialInstitution>().label('Bank').required(),
		connectionType: yup
			.mixed<BankConnectionType>()
			.label('Connection Type')
			.oneOf(BankConnectionTypes)
			.required(),
		connectionSettings: yup
			.mixed<BankConnectionSettingsType>()
			.when('bank', ([bank], schema) => {
				if (bank !== null)
					return schema
						.concat<any, yup.AnyObject, any, ''>(
							getConnectionSettingsValidator(bank.bankCode)!,
						)
						.required();
				else return schema.nullable();
			})
			.defined(),
		e4Entities: yup
			.array<LegalEntity>()
			.label('Entities')
			.required()
			.when('connectionType', ([connectionType], schema) => {
				if (connectionType === 'Entity') return schema.min(1);
				else return schema.length(0);
			}),
		e4Accounts: yup
			.array<Account>()
			.label('Accounts')
			.required()
			.when('connectionType', ([connectionType], schema) => {
				if (connectionType === 'Account') return schema.min(1);
				else return schema.length(0);
			}),
	});
