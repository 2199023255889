/* eslint-disable mobx/missing-observer */
import {
	Alert,
	Chip,
	Grid,
	ListItemText,
	MenuItem,
	Typography,
} from '@mui/material';
import {
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { BankConnection } from 'modules/clients/apiGateway/payments4/bankConnections';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { T4DataGrid } from 'shared/components/dataGrid/dataGrid';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { TooltipAdornment } from 'shared/components/tooltipAdornment';
import { paths } from 'shared/constants/paths';
import { getDateColumnDefinition } from 'shared/utilities/dataGrid/columnDefinitions';
import {
	DataGridColumnWidths,
	getOptionsMenuColDef,
	USER_PREFERENCES_FIELD_OPTIONS,
} from 'shared/utilities/dataGrid/dataGridUtils';
import { BankConnectionType } from './constants';

export const BankConnectionsPageTab: FC<{
	bankCode: string;
	isLoading: boolean;
	connections: BankConnection[];
	setSelectedConnectionId: (id: string | null) => void;
	createConnectionButton: JSX.Element;
}> = ({
	bankCode,
	isLoading,
	connections,
	setSelectedConnectionId,
	createConnectionButton,
}) => {
	const history = useHistory();

	const sharedColumns: GridColDef[] = useMemo(
		() => [
			{
				...getOptionsMenuColDef(
					(params: GridRenderCellParams<BankConnection>) => (
						<OverflowMenu id="payment-bank-connection-options-menu">
							<MenuItem
								onClick={() => {
									setSelectedConnectionId(params.row.id);
									history.push(
										`${paths.administration.payments4.paymentConnectionManagement.href}/${params.row.id}`,
									);
								}}
							>
								<ListItemText>View Details</ListItemText>
							</MenuItem>
						</OverflowMenu>
					),
				),
			},
			{
				field: 'type',
				type: 'string',
				headerName: 'Type',
				description: 'Type',
				valueGetter: (
					params: GridValueGetterParams<BankConnection>,
				): BankConnectionType => {
					if (params.row.e4EntityIds.length > 0) return 'Entity';
					else if (params.row.e4AccountIds.length > 0) return 'Account';
					else return 'Global';
				},
				renderCell: (params: GridRenderCellParams<BankConnection>) => (
					<Chip label={params.value} sx={{ height: '75%' }} />
				),
				minWidth: DataGridColumnWidths.small,
				flex: 1,
			},
			{
				type: 'string',
				field: 'name',
				headerName: 'Connection Name',
				description: 'Connection Name',
				minWidth: DataGridColumnWidths.medium,
				flex: 2,
			},
			{
				...getDateColumnDefinition(),
				field: 'createdOn',
				headerName: 'Created Date',
				description: 'Created Date',
				minWidth: DataGridColumnWidths.small,
				flex: 1,
			},
			{
				type: 'string',
				field: 'createdBy',
				headerName: 'Created By',
				description: 'Created By',
				valueGetter: (params: GridValueGetterParams<BankConnection>) =>
					params.row.createdBy.email ?? params.row.createdBy.userId,
				minWidth: DataGridColumnWidths.medium,
				flex: 2,
			},
		],
		[history, setSelectedConnectionId],
	);

	const entityConnectionColumns: GridColDef[] = useMemo(
		() => [
			...sharedColumns,
			{
				type: 'number',
				field: 'numberOfEntities',
				headerName: 'Number of Entities',
				description: 'Number of Entities',
				valueGetter: (params: GridValueGetterParams<BankConnection>) =>
					params.row.e4EntityIds.length,
				headerAlign: 'left',
				align: 'left',
				minWidth: DataGridColumnWidths.medium,
				flex: 1,
			},
		],
		[sharedColumns],
	);

	const accountConnectionColumns: GridColDef[] = useMemo(
		() => [
			...sharedColumns,
			{
				type: 'number',
				field: 'numberOfAccounts',
				headerName: 'Number of Accounts',
				description: 'Number of Accounts',
				valueGetter: (params: GridValueGetterParams<BankConnection>) =>
					params.row.e4AccountIds.length,
				headerAlign: 'left',
				align: 'left',
				minWidth: DataGridColumnWidths.medium,
				flex: 1,
			},
		],
		[sharedColumns],
	);

	return (
		<div role="tabpanel">
			<Grid container item direction="column" sx={{ gap: 2 }}>
				<Grid
					item
					xs="auto"
					sx={{ display: 'flex', justifyContent: 'flex-end' }}
				>
					{createConnectionButton}
				</Grid>

				<Grid container item xs="auto" sx={{ gap: 1 }}>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
					>
						<Typography variant="h3">Global Connections</Typography>
						<TooltipAdornment
							text="These credentials apply to all entities and accounts with this bank."
							tooltipPlacement="right"
							hover
						/>
					</Grid>
					<Grid item xs={12} sx={{ maxHeight: '50vh', display: 'grid' }}>
						<T4DataGrid<BankConnection>
							stonlyId={`payment-global-bank-connections-${bankCode}`}
							loading={isLoading}
							columns={sharedColumns}
							rows={connections.filter((x) => x.isGlobalConnection)}
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							autoHeight
							slots={{
								noRowsOverlay: () => (
									<div
										style={{
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Alert severity="info">
											There are no global connections for this bank.
										</Alert>
									</div>
								),
							}}
						/>
					</Grid>
				</Grid>

				<Grid container item xs="auto" sx={{ gap: 1 }}>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
					>
						<Typography variant="h3">Entity Connections</Typography>
						<TooltipAdornment
							text="These credentials apply to specific entities and all of their accounts with this bank."
							tooltipPlacement="right"
							hover
						/>
					</Grid>
					<Grid item xs={12} sx={{ maxHeight: '40vh', display: 'grid' }}>
						<T4DataGrid<BankConnection>
							stonlyId={`payment-entity-bank-connections-${bankCode}`}
							loading={isLoading}
							columns={entityConnectionColumns}
							rows={connections.filter((x) => x.e4EntityIds.length > 0)}
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							autoHeight
							slots={{
								noRowsOverlay: () => (
									<div
										style={{
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Alert severity="info">
											There are no entity specific connections for this bank.
										</Alert>
									</div>
								),
							}}
						/>
					</Grid>
				</Grid>

				<Grid container item xs="auto" sx={{ gap: 1 }}>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
					>
						<Typography variant="h3">Account Connections</Typography>
						<TooltipAdornment
							text="These credentials apply to specific accounts with this bank."
							tooltipPlacement="right"
							hover
						/>
					</Grid>
					<Grid item xs={12} sx={{ maxHeight: '40vh', display: 'grid' }}>
						<T4DataGrid<BankConnection>
							stonlyId={`payment-account-bank-connections-${bankCode}`}
							loading={isLoading}
							columns={accountConnectionColumns}
							rows={connections.filter((x) => x.e4AccountIds.length > 0)}
							initialState={{
								pinnedColumns: { left: [USER_PREFERENCES_FIELD_OPTIONS] },
							}}
							autoHeight
							slots={{
								noRowsOverlay: () => (
									<div
										style={{
											height: '100%',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Alert severity="info">
											There are no account specific connections for this bank.
										</Alert>
									</div>
								),
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
};
