/* eslint-disable mobx/missing-observer */
import { Add, SettingsInputComponent } from '@mui/icons-material';
import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { BreadcrumbList } from 'features/entity4/shared/components/breadcrumbs/breadcrumbList';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import { T4DataResponse2 } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { NoRowsOverlay } from 'shared/components/dataGrid/noRowsOverlay';
import { PageHeader } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import {
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from 'shared/constants/cannotDisplayMessaging';
import { paths, validIdRegex } from 'shared/constants/paths';
import { useClients } from 'shared/hooks/useClients';
import { BankConnectionsPageTab } from './bankConnectionsPageTab';
import { CreateBankConnectionDrawer } from './createBankConnectionDrawer';
import { useGetAllBankConnections } from './hooks/useGetBankConnections';

export const BankConnectionsPageRoutes: FC = () => {
	return (
		<Switch>
			<Route
				path={`${paths.administration.payments4.paymentConnectionManagement.href}/:bankConnectionId`.concat(
					validIdRegex,
				)}
				exact
			>
				<BankConnectionsPage />
			</Route>
			<Route
				path={paths.administration.payments4.paymentConnectionManagement.href}
				exact
			>
				<BankConnectionsPage />
			</Route>

			<Route>
				<CannotDisplay
					headingText={NOT_FOUND_MESSAGING.HEADING}
					bodyText={NOT_FOUND_MESSAGING.BODY}
					imageSrc={NOT_FOUND_MESSAGING.IMAGE}
					buttonText={RETURN_TO_HOME}
					buttonHref={paths.root.href}
				/>
			</Route>
		</Switch>
	);
};

export const BankConnectionsPage: FC = () => {
	const { bankConnectionId } = useParams<{
		bankConnectionId: string | undefined;
	}>();

	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading: areBankConnectionsLoading,
		isFetching: areBankConnectionsFetching,
		data: connections,
		error: loadingBankConnectionsError,
		refetch,
	} = useGetAllBankConnections();
	useEffect(() => {
		if (loadingBankConnectionsError) {
			enqueueSnackbar(loadingBankConnectionsError.message, {
				variant: 'error',
			});
		}
	}, [loadingBankConnectionsError, enqueueSnackbar]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useQuery<FinancialInstitution[], Error>(
		['financial-institutions'],
		async () => {
			const response =
				await applicationApiClient.financialInstitutions.getAll();
			if (response.status === 200 && response.data)
				return (
					response.data as T4DataResponse2<FinancialInstitution[]>
				).data.sort((a, b) =>
					(
						a.displayName ??
						a.bankCode ??
						a.financialInstitutionId
					).localeCompare(
						b.displayName ?? b.bankCode ?? b.financialInstitutionId,
					),
				);
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			staleTime: 120000,
		},
	);
	useEffect(() => {
		if (loadingFinancialInstitutionsError) {
			enqueueSnackbar(
				'Unable to load financial institutions. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingFinancialInstitutionsError, enqueueSnackbar]);

	const isLoading = useMemo(
		() =>
			(areBankConnectionsLoading && areBankConnectionsFetching) ||
			(areFinancialInstitutionsLoading && areFinancialInstitutionsFetching),
		[
			areBankConnectionsLoading,
			areBankConnectionsFetching,
			areFinancialInstitutionsLoading,
			areFinancialInstitutionsFetching,
		],
	);

	const bankTabList = useMemo(() => {
		const uniqueBankCodes = [
			...new Set((connections ?? []).map((x) => x.bankCode)),
		];
		return uniqueBankCodes
			.map((code) => {
				const match = (financialInstitutions ?? []).find(
					(x) => x.bankCode === code,
				);
				return {
					name: match?.displayName ?? code,
					bankCode: code,
				};
			})
			.sort((a, b) => a.name.localeCompare(b.name));
	}, [connections, financialInstitutions]);
	const getBankTabIndex = useCallback(
		(bankCode: string) => bankTabList.findIndex((x) => x.bankCode === bankCode),
		[bankTabList],
	);

	const [selectedConnectionId, setSelectedConnectionId] = useState<
		string | null
	>(null);
	useEffect(() => {
		setSelectedConnectionId(bankConnectionId ?? null);
	}, [bankConnectionId]);

	const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState<boolean>(false);

	const [tabIndex, setTabIndex] = useState<number>(0);

	const createConnectionButton = useMemo(
		() => (
			<Button
				variant="outlined"
				startIcon={<Add />}
				disabled={isLoading}
				onClick={() => setIsCreateDrawerOpen(true)}
			>
				Connection
			</Button>
		),
		[isLoading],
	);

	const tabContainer = useMemo(
		() => (
			<Tabs
				indicatorColor="primary"
				value={tabIndex}
				onChange={(_, index) => setTabIndex(index)}
				sx={{ marginBottom: '1rem' }}
				variant="scrollable"
				scrollButtons="auto"
			>
				{bankTabList.map((bankTab, index) => (
					<Tab key={bankTab.bankCode} label={bankTab.name} tabIndex={index} />
				))}
			</Tabs>
		),
		[tabIndex, bankTabList],
	);
	const activeTab = useMemo(() => {
		const active = bankTabList.at(tabIndex);
		if (active)
			return (
				<BankConnectionsPageTab
					bankCode={active.bankCode}
					isLoading={isLoading}
					connections={(connections ?? []).filter(
						(x) => x.bankCode === active.bankCode,
					)}
					setSelectedConnectionId={setSelectedConnectionId}
					createConnectionButton={createConnectionButton}
				/>
			);
		else return null;
	}, [tabIndex, bankTabList, isLoading, connections, createConnectionButton]);

	const createDrawer = useMemo(
		() => (
			<CreateBankConnectionDrawer
				isOpen={isCreateDrawerOpen}
				setIsOpen={setIsCreateDrawerOpen}
				refetch={refetch}
				getBankTabIndex={getBankTabIndex}
				setTabIndex={setTabIndex}
			/>
		),
		[isCreateDrawerOpen, refetch, getBankTabIndex],
	);

	return (
		<T4View
			header={
				<PageHeader
					id="payment-connection-management"
					title="Payment Connection Management"
					breadcrumbs={
						<BreadcrumbList
							breadcrumbs={[
								{
									label: 'Administration',
									href: paths.administration.href,
								},
							]}
						/>
					}
				/>
			}
			loading={isLoading}
		>
			{connections && connections.length === 0 ? (
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						gap: 2,
						flexWrap: 'nowrap',
					}}
				>
					<Grid
						item
						xs="auto"
						sx={{ display: 'flex', justifyContent: 'flex-end' }}
					>
						{createConnectionButton}
					</Grid>
					<Grid
						item
						xs
						sx={(theme) => ({
							height: '100%',
							border: `1px solid ${theme.palette.charcoal[50]}`,
							borderRadius: '0.5rem',
						})}
					>
						<NoRowsOverlay
							icon={SettingsInputComponent}
							heading={
								<Typography variant="h3">No Connections Exist</Typography>
							}
							body={
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'center',
										gap: 1,
									}}
								>
									<Typography variant="body1">
										You're almost ready to start making payments! Set up your
										first global connection to get started.
									</Typography>
									<Button
										variant="outlined"
										startIcon={<Add />}
										onClick={() => setIsCreateDrawerOpen(true)}
									>
										Global Connection
									</Button>
								</Box>
							}
						/>
					</Grid>
				</Grid>
			) : (
				<Grid
					container
					direction="column"
					sx={{
						height: '100%',
						gap: 2,
						flexWrap: 'nowrap',
					}}
				>
					{tabContainer}
					{activeTab}
				</Grid>
			)}

			{createDrawer}
		</T4View>
	);
};
