/* eslint-disable mobx/missing-observer */
import { Collapse, Grid } from '@mui/material';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { FC, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getConnectionSettingsFieldDefinitions } from './utilities';

export const ConnectionSettingsForm: FC<{
	certificateNames: string[];
}> = ({ certificateNames }) => {
	const { control, watch } = useFormContext();

	const bank = watch('bank');

	const fieldDefinitions = useMemo(
		() => getConnectionSettingsFieldDefinitions(bank?.bankCode) ?? [],
		[bank],
	);

	return (
		<Collapse in={!!bank} sx={{ width: '100%' }}>
			<Grid container item xs={12} sx={{ gap: 2 }}>
				{fieldDefinitions.map((field, index) => {
					if (field.type === 'certificate')
						return (
							<Grid item xs={12} key={field.key}>
								<Controller
									name={`connectionSettings.${field.key}`}
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error },
									}) => (
										<T4Autocomplete<string>
											id={field.key}
											label={field.name}
											value={value}
											options={certificateNames}
											onChange={(_, value) => onChange(value)}
											error={!!error}
											helperText={error && error.message}
											required
										/>
									)}
								/>
							</Grid>
						);

					return (
						<Grid item xs={12} key={field.key}>
							<Controller
								name={`connectionSettings.${field.key}`}
								control={control}
								render={({
									field: { onChange, value },
									fieldState: { error },
								}) => (
									<T4TextFieldV2
										id={field.key}
										label={field.name}
										value={value}
										onChange={onChange}
										error={!!error}
										helperText={error && error.message}
										isSecret={field.isSecret}
										required
									/>
								)}
							/>
						</Grid>
					);
				})}
			</Grid>
		</Collapse>
	);
};
