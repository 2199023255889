import { useQuery } from '@tanstack/react-query';
import { BankConnection } from 'modules/clients/apiGateway/payments4/bankConnections';
import { T4DataResponse2 } from 'modules/clients/types';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export const useGetAllBankConnections = () => {
	const { applicationApiClient } = useClients();

	const loadConnections = useCallback(async () => {
		try {
			const response =
				await applicationApiClient.payments4.bankConnections.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<BankConnection[]>).data;
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				error?.message ??
					'Unable to load bank connections. Please try again later.',
			);
		}
	}, [applicationApiClient]);

	return useQuery<BankConnection[], Error>(
		['payment-bank-connections'],
		loadConnections,
		{
			refetchOnWindowFocus: false,
		},
	);
};

export const useGetBankConnection = (id: string | null) => {
	const { applicationApiClient } = useClients();

	const loadConnection = useCallback(async () => {
		if (id === null) return;
		try {
			const response = await applicationApiClient.payments4.bankConnections.get(
				id,
			);
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<BankConnection>).data;
			else if (response.status === 404)
				throw new Error('Bank connection not found.');
			else throw new Error();
		} catch (error: any) {
			throw new Error(
				error?.message ??
					'Unable to load bank connections. Please try again later.',
			);
		}
	}, [id, applicationApiClient]);

	return useQuery<BankConnection | undefined, Error>(
		['payment-bank-connection', id],
		loadConnection,
		{
			refetchOnWindowFocus: false,
			enabled: id !== null,
		},
	);
};
